import './css/breadcrumb.scss'
import './css/main.scss'
import './css/homepage.scss'
import './css/spinner.scss'
import './jquery.formset'

import 'select2'
import 'select2/dist/css/select2.min.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'

import 'devbridge-autocomplete'

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/save'
import 'tinymce/plugins/table'
import 'tinymce/skins/ui/oxide/skin.min.css'

let $el = $('.notes')
if ($el) {
  $el.popover({
    placement: 'bottom',
    container: 'body'
  })
}
$.fn.datepicker.defaults.format = 'yyyy-mm-dd'
$.fn.datepicker.defaults.autoclose = true
$.fn.datepicker.defaults.orientation = 'bottom'
$.fn.select2.defaults.set('theme', 'bootstrap')

function addCurrency (rsText, currency) {
  return rsText + ' ' + currency
}

// Patch for WEBFORM-243, check that the line article is valid before taking it into account
function validateArticle (elementId, elementName) {
  // Return true if every variable is filled, false if at least one variable is empty
  let variablesNames = ['catalogue_code', 'reference_name', 'shipping_group', 'quantity', 'unit_price', 'subtotal', 'shipping_price', 'total_shipping_price']
  return !variablesNames.some(name => !$('#' + elementId.replace(elementName, name)).val())
}

function total (elementName, whatTotal) {
  let sum = 0
  $('.' + elementName).each(function () {
    if (validateArticle($(this).attr('id'), elementName)) {
      sum += parseFloat($(this).val())
    }
  })
  whatTotal.text(sum.toFixed(2))
  $('.total_net').text((Number($('.handling_charges').text()) + Number($('.total_sum').text())).toFixed(2))
  $('#total_net').text(parseFloat($('.total_net').text()))
  $('#total_sum').text(parseFloat($('.total_sum').text()))
  $('#handling_charges').text(parseFloat($('.handling_charges').text()))
}

let dataURL = $('#wizard-container').data()

function fillRowArticles (elValue, id1, replace, replacements) {
  $.ajax({
    url: dataURL.showUrl,
    data: {'what': elValue, 'select': id1},
    error: function (xhr, ajaxOptions, thrownError) {
      $(id1).attr('value', '404 not found')
    },
    success: function (data) {
      for (let i = 0; i < replacements.length; i++) {
        let replacement = replacements[i]
        let id2 = id1.replace(replace, replacement)

        if (i === 0) {
          let idIcon = id1.replace(replace, 'shipping-icon')
          let idShipping = id1.replace(replace, 'shipping_group')
          let label = ''
          if ((data['group_label'] !== 'error') && (data['group_label'] !== '')) {
            label = data['group_label']
          }
          if ((data['alert_message'] !== 'error') && (data['alert_message'] !== '')) {
            $(idIcon).removeClass('hidden')
            if (label !== '') {
              $(idIcon).removeClass('hidden')
              label = label + '<br>' + data['alert_message']
            } else {
              label = data['alert_message']
              $(idIcon).removeClass('hidden')
            }
          } else {
            $(idIcon).addClass('hidden')
          }
          if (label !== '' && label !== undefined) {
            $(idShipping).tooltip({
              placement: 'bottom',
              html: true,
              title: label
            })
          }
        }
        let name = ''
        if (data[replacement] !== 'error') {
          name = data[replacement]
        }
        if (id2.indexOf('details') !== -1) {
          if (data.reference_name) {
            if (!(data.reference_name) || (data.reference_name === 'error')) {
              $(id2).addClass('inactive')
            } else {
              $(id2).removeClass('inactive')
            }
          } else {
            if (!(data.catalogue_code) || (data.catalogue_code === 'error')) {
              $(id2).addClass('inactive')
            } else {
              $(id2).removeClass('inactive')
            }
          }
        }
        if ($.isNumeric(name) && (id2.indexOf('unit_price') >= 0)) {
          $(id2).val(addCurrency(parseFloat(name).toFixed(2), '€'))
          // $(id2).val(parseFloat(name).toFixed(2))
        } else if ($.isNumeric(name) && (id2.indexOf('subtotal') >= 0)) {
          $(id2).val(addCurrency(parseFloat(name).toFixed(2), '€'))
        } else {
          $(id2).val(name)
        }
      }
      total('subtotal', $('.total_sum'))
      total('total_shipping_price', $('.handling_charges'))
    }
  })
}

function fillAllRowsArticles (classWf, replace, replacements, path) {
  fillRowArticles($(this).val(), '#' + $(this).attr('id'), replace, replacements)
  classWf.on('change keyup', function (event) {
    fillRowArticles($(this).val(), '#' + $(this).attr('id'), replace, replacements)
  })

  classWf.autocomplete(
    {
      serviceUrl: path,
      // autoSelectFirst: true,
      minChars: 0,
      onSelect: function (el) { // item selected
        fillRowArticles(el.value, '#' + $(this).attr('id'), replace, replacements)
      }
    })
}

$('.quantity').on('change keyup', function (e) {
  // Check that the article line is valid before updating totals
  if (validateArticle($(this).attr('id'), 'quantity')) {
    let quantity = $(this).val()
    let idUnitPrice = '#' + $(this).attr('id').replace('quantity', 'unit_price')
    let idShippingPrice = '#' + $(this).attr('id').replace('quantity', 'shipping_price')
    let idTotalShippingPrice = '#' + $(this).attr('id').replace('quantity', 'total_shipping_price')
    let idSubtotal = '#' + $(this).attr('id').replace('quantity', 'subtotal')
    let unitPrice = parseFloat($(idUnitPrice).val())
    let shippingPrice = $(idShippingPrice).val()
    $(idSubtotal).val(addCurrency((quantity * unitPrice).toFixed(2), '€'))
    $(idTotalShippingPrice).val(quantity * shippingPrice)
    total('subtotal', $('.total_sum'))
    total('total_shipping_price', $('.handling_charges'))
  }
})

function handleRadios (forWhat, idPanel) {
  $('#id_customer-' + forWhat + '_status_1:radio').on('click', function (e) {
    $(idPanel).addClass('in').attr('data-target', idPanel + '.in').attr('aria-expanded', 'true')
    $('#export_' + forWhat).removeClass('hidden')
    $('#import_' + forWhat).removeClass('hidden')
  })
  $('#id_customer-' + forWhat + '_status_0:radio').on('click', function (e) {
    $(idPanel).removeClass('in').removeAttr('data-target').attr('aria-expanded', 'false')
    $('#export_' + forWhat).addClass('hidden')
    $('#import_' + forWhat).addClass('hidden')
  })
  if ($('#id_customer-' + forWhat + '_status_1:radio').is(':checked')) {
    $(idPanel).addClass('in').attr('data-target', idPanel + '.in').attr('aria-expanded', 'true')
    $('#export_' + forWhat).removeClass('hidden')
    $('#import_' + forWhat).removeClass('hidden')
  }
}

$('#agree:checkbox').on('click', function (e) {
  if (this.checked) {
    $('#captcha').removeClass('hidden')
    $('#submit-request').removeClass('disabled')
    // $('#confirmation').removeClass('disabled')
  } else {
    $('#captcha').addClass('hidden')
    $('#submit-request').addClass('disabled')
    $('#confirmation').addClass('disabled')
  }
})

function initDetails () {
  $('.details').click(function () {
    let idCode = '#' + $(this).attr('id').replace('details', 'catalogue_code')
    if ($(idCode).val()) {
      window.open('https://crs.edqm.eu/db/4DCGI/View=' + $(idCode).val(), 'Details for ', 'window settings')
      return false
    }
    return false
  })
}

let arrayTiny = [
  'welcome_en', 'welcome_fr', 'confirmation_order_en', 'confirmation_order_fr',
  'confirmation_quotation_en', 'confirmation_quotation_fr',
  'dear_customer_order_en', 'dear_customer_order_fr',
  'dear_customer_quotation_en', 'dear_customer_quotation_fr', 'alerts_en',
  'alerts_fr', 'comment_info'
]

function initTiny (allEl) {
  tinymce.remove()
  for (let i = 0; i < allEl.length; i++) {
    let tinyId = '#id_' + allEl[i]
    tinymce.init({
      content_css: false,
      skin: false,
      selector: tinyId,
      branding: false,
      menubar: false,
      statusbar: false,
      theme_advanced_fonts: 'Open Sans=Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
      paste_word_valid_elements: '-strong/b,-em/i,-span,-p,-ol,-ul,-li,-table,-tr,-td[colspan|rowspan],-th,-thead,-tfoot,-tbody,-a[href|name],sub,sup,strike,br,u',
      paste_webkit_styles: 'color',
      paste_retain_style_properties: 'color',
      forced_root_block: false,
      force_br_newlines: true,
      force_p_newlines: false,
      plugins: 'paste autoresize lists link table',
      toolbar: 'undo redo | styleselect | bold italic | link | alignleft aligncenter alignright | numlist bullist | forecolor backcolor | table',
      setup: function (editor) {
        editor.on('change', function () {
          tinymce.triggerSave()
        })
      }
    })
  }
}

function initFormsets () {
  $('.products-formset').formset(
    {
      prefix: 'articles',
      formCssClass: 'products-formset',
      addText: '<button class="btn btn-success add-form-row">+</button>',
      addTitle: gettext('Add row'),
      deleteText: '<button id="remove_row" class="btn btn-danger remove-form-row">-</button>',
      deleteTitle: gettext('Remove row'),
      minForms: 10,
      added: function (row) {
        let totalForms = $('#id_articles-TOTAL_FORMS').val()

        row.find('.counter').html(totalForms)
        row.find('.details').attr('id', 'id_articles-' + (totalForms - 1) + '-details')
        row.find('.counter').attr('id', 'id_articles-' + (totalForms - 1) + '-counter')
        row.find('.warning-glyph').attr('id', 'id_articles-' + (totalForms - 1) + '-shipping-icon')

        initDetails()

        fillAllRowsArticles($('.catalogue_code-autocomplete'), 'catalogue_code',
          ['reference_name', 'shipping_group', 'quantity', 'unit_price', 'subtotal', 'shipping_price', 'total_shipping_price', 'details'],
          dataURL.codeUrl)

        fillAllRowsArticles($('.reference_name-autocomplete'), 'reference_name',
          ['catalogue_code', 'shipping_group', 'quantity', 'unit_price', 'subtotal', 'shipping_price', 'total_shipping_price', 'details'],
          dataURL.referenceUrl)
      },
      removed: function (row) {
        total('subtotal', $('.total_sum'))
        total('total_shipping_price', $('.handling_charges'))
      }
    })
}

function initFields () {
  initDetails()
  $('[data-toggle="tooltip"]').tooltip()
}

function download (content, fileName, contentType) {
  let a = document.createElement('a')
  let file = new Blob([content], {type: contentType})
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName)
  } else {
    a.href = URL.createObjectURL(file)
    a.download = fileName
    a.click()
  }
}

let arrayInfo = ['code_client', 'reference', 'company_name', 'address_line1', 'address_line2', 'address_line3', 'address_line4',
  'city', 'postcode', 'state_usa', 'vat_eu', 'country', 'contact_first_name', 'contact_last_name', 'position',
  'phone', 'fax', 'email']

let arrayTypeInfo = ['customer', 'shipping', 'invoice', 'billing']

function getValues (prefix) {
  let data = {}
  for (let i = 0; i < arrayInfo.length; i++) {
    let val_ = arrayInfo[i]
    data[val_] = $('#' + prefix + val_).val()
  }
  return data
}

function performClick (elemId) {
  let elem = document.getElementById(elemId)
  if (elem && document.createEvent) {
    let evt = document.createEvent('MouseEvents')
    evt.initEvent('click', true, false)
    elem.dispatchEvent(evt)
  }
}

function exportInfoCustomer (typeInfo) {
  let idExport = 'export_' + typeInfo
  let prefix = 'id_customer-' + typeInfo + '_'
  let prename = 'rsform-' + typeInfo

  $('#' + idExport).click(function (e) {
    e.preventDefault()
    let jsonData = JSON.stringify(getValues(prefix))
    download(jsonData, prename + '-info.edqm', 'text/plain')
  })
}

function importInfoCustomer (typeInfo) {
  let idImport = 'import_' + typeInfo
  let prefix = 'id_customer-' + typeInfo + '_'
  let theFile = 'theFile_' + typeInfo
  $('#' + idImport).click(function (e) {
    e.preventDefault()
    performClick(theFile)
  })
  $('#' + theFile).on('change', function (e) {
    if (!$('#' + theFile).val().endsWith('.edqm')) {
      $('#wrong-import-type').modal('show')
      $('#' + theFile).val('')
      return false
    }
    let files = document.getElementById(theFile).files
    if (files.length <= 0) {
      return false
    }
    let fr = new FileReader()
    fr.onload = function (e) {
      let result = JSON.parse(e.target.result)
      let showOldImportWarning = false
      for (let field in result) {
        let $formField = $('#' + prefix + field)

        // Backwards compatibility: since we split the "name" field into two
        // separate fields, we assign the old data to the "last_name" field.
        if (field.endsWith('contact_name')) {
          const contactPrefix = field.substr(0, field.lastIndexOf('contact_name'))
          $formField = $('#' + prefix + contactPrefix + 'contact_last_name')
          showOldImportWarning = true
        }

        $formField.val(result[field])
      }

      if (showOldImportWarning) {
        alert("You have imported an address in an old format. Please add the contact's first name and re-export.")
      }
    }
    fr.readAsText(files.item(0))
  })
}

function initShippingGroup () {
  $('.shipping_group-autocomplete').each(function () {
    // let idCode = $('#' + this.id.replace('shipping_group', 'catalogue_code'))
    let idShippingGroup = '#' + this.id
    if ($(idShippingGroup).val()) {
      $.ajax({
        url: dataURL.checkShippingGroupUrl,
        data: {'shipping_group': $(idShippingGroup).val()},
        error: function (xhr, ajaxOptions, thrownError) {
          idShippingGroup.attr('value', '404 not found')
        },
        success: function (data) {
          let idIcon = idShippingGroup.replace('shipping_group', 'shipping-icon')
          let label = ''
          if ((data['group_label'] !== 'error') && (data['group_label'] !== '')) {
            label = data['group_label']
          }
          if ((data['alert_message'] !== 'error') && (data['alert_message'] !== '')) {
            $(idIcon).removeClass('hidden')
            if (label !== '') {
              label = label + '<br>' + data['alert_message']
            } else {
              label = data['alert_message']
            }
          } else {
            $(idIcon).addClass('hidden')
          }
          if (label !== '' && label !== undefined) {
            $(idShippingGroup).tooltip({
              placement: 'bottom',
              html: true,
              title: label
            })
          }
        }
      })
    }
  })
}

$(document).ready(function () {
  $('#submit-request').on('click', function (e) {
    $('#loading').addClass('loading')
    window.history.pushState(null, null, window.location.href)
  }).on('mouseup', function (e) {
    $(window).one('popstate', function () {
      window.location.reload()
      return false
    })
  })

  arrayTypeInfo.forEach(exportInfoCustomer)
  arrayTypeInfo.forEach(importInfoCustomer)

  $('.details').each(function () {
    let idCode = $('#' + this.id.replace('details', 'catalogue_code'))
    let thisId = this.id
    if (idCode.val()) {
      $('#' + thisId).removeClass('inactive')
    }
  })

  $('.datepicker').datepicker()

  handleRadios('shipping', '#collapse4')
  handleRadios('billing', '#collapse2')
  handleRadios('invoice', '#collapse3')

  fillAllRowsArticles($('.catalogue_code-autocomplete'), 'catalogue_code',
    ['reference_name', 'shipping_group', 'quantity', 'unit_price', 'subtotal', 'shipping_price', 'total_shipping_price', 'details'],
    dataURL.codeUrl)

  fillAllRowsArticles($('.reference_name-autocomplete'), 'reference_name',
    ['catalogue_code', 'shipping_group', 'quantity', 'unit_price', 'subtotal', 'shipping_price', 'total_shipping_price', 'details'],
    dataURL.referenceUrl)

  initShippingGroup()

  initFormsets()

  initFields()

  initTiny(arrayTiny)

  total('subtotal', $('.total_sum'))
  total('total_shipping_price', $('.handling_charges'))

  // function that check if class element exists and has value
  function hasValue (elem) {
    if (elem.length) {
      return $(elem).filter(function () {
        return $(this).val()
      }).length > 0
    }
  }

  // When the catalogue code or reference is empty all the other fields should be empty
  if (!hasValue($('.catalogue_code-autocomplete'))) {
    $('.quantity').val('')
    $('.subtotal').val('')
    $('.unit_price-autocomplete').val('')
    $('.shipping_price').val('')
    $('.total_shipping_price').val('')
  }

  $(function () {
    // Add refresh button after field (this can be done in the template as well)
    $('img.captcha').after(
      $('<a href="#" class="captcha-refresh"><span class="captcha glyphicon glyphicon-refresh" title="Refresh Captcha"></span></a><br>')
    )

    // Click-handler for the refresh-link
    $('.captcha-refresh').click(function () {
      $.getJSON('/captcha/refresh/', function (result) {
        $('.captcha').attr('src', result['image_url'])
        $('#id_summary-captcha_0').val(result['key'])
      })
      return false
    })

    $('form input').keydown(function (e) {
      if (e.keyCode === 13) {
        let inputs = $(this).parents('form').eq(0).find(':input')
        if (inputs[inputs.index(this) + 1] != null) {
          inputs[inputs.index(this) + 1].focus()
        }
        e.preventDefault()
        return false
      }
    })
  })

  $('a#link_id').click(function () {
    let href = $(this).attr('href')
    if (href) {
      window.open(href, 'window name', 'window settings')
    }
    return false
  })
})
